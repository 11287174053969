import { render, staticRenderFns } from "./questionnaireDetail.vue?vue&type=template&id=368dd2ec&scoped=true"
import script from "./questionnaireDetail.vue?vue&type=script&lang=js"
export * from "./questionnaireDetail.vue?vue&type=script&lang=js"
import style0 from "./questionnaireDetail.vue?vue&type=style&index=0&id=368dd2ec&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "368dd2ec",
  null
  
)

export default component.exports