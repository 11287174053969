<template>
  <div class="questionnaireDetail">
    <div class="scroll-view">
      <div class="header">
        <div class="header-title">{{ title ? title : "" }}</div>
        <div class="header-subtitle">
          <div class="sub-wrap">
            <div class="sub-wrap_icon">
              <img :src="require('./img/people.png')" />
            </div>
            <div class="sub-wrap_text">参与人数</div>
            <div class="sub-wrap_num">{{ answerCount }}</div>
          </div>
          <div
            class="sub-tag"
            :class="{ hasAnswer: hasAnswer !== 1 }"
            v-if="isView"
          >
            {{ hasAnswerMap[hasAnswer] }}
          </div>
        </div>
      </div>
      <div class="detail">
        <v-h5Mtml :content="description"></v-h5Mtml>
      </div>
      <div class="main">
        <div
          class="content-wrap"
          v-for="(item, index) in listData"
          :key="index"
          :id="`questionnaireDetail_${index}`"
        >
          <div class="content-wrap_title">
            {{ `${index + 1}、${item.title}` }}
            <template v-if="item.quType === 2">{{
              `${item.minSelection ? `最少选择${item.minSelection}项，` : ""}${
                item.maxSelection ? `最多选择${item.maxSelection}项` : ""
              }（多选）`
            }}</template>
            <template v-if="item.quType === 5">
              {{ "【矩阵量表题】从1-5进行打分" }}
            </template>
          </div>
          <div class="content-wrap_type" v-if="item.quType">
            <!--单选 -->
            <div class="single" v-if="item.quType === 1">
              <div
                class="option-wrap"
                v-for="(opt, oIndex) in item.optionList"
                :key="oIndex"
                :style="{
                  'background-image': !isView
                    ? 'none'
                    : 'linear-gradient(90deg,#e7f0fd 0%,#accbee 100%)',
                }"
                :class="{ optionSelect: getIsSelect(opt, item) }"
                @click="setOptAnswer(opt, item)"
              >
                <div
                  v-show="isView"
                  class="option-progress"
                  :style="{
                    width: `${100 - getPercent(opt, item.optionList)}%`,
                  }"
                ></div>
                <div class="option-text_copy">
                  {{ `${opt.optionKey}、${opt.optionTitle || ""}` }}
                </div>
                <div class="option-text">
                  {{ `${opt.optionKey}、${opt.optionTitle || ""}` }}
                </div>
                <div class="option-icon">
                  <img :src="require('./img/check.png')" />
                </div>
                <div class="option-data" v-show="isView">
                  <div class="label">{{ opt.checkedCount || 0 }}人</div>
                  <div class="value">
                    {{ `${getPercent(opt, item.optionList)}%` }}
                  </div>
                </div>
              </div>
            </div>
            <!-- 多选 -->
            <div class="multi" v-if="item.quType === 2">
              <div
                class="option-wrap"
                v-for="(opt, oIndex) in item.optionList"
                :key="oIndex"
                :class="{ optionSelect: getIsSelect(opt, item) }"
                :style="{
                  'background-image': !isView
                    ? 'none'
                    : 'linear-gradient(90deg,#e7f0fd 0%,#accbee 100%)',
                }"
                @click="setOptAnswer(opt, item)"
              >
                <div
                  v-show="isView"
                  class="option-progress"
                  :style="{
                    width: `${100 - getPercent(opt, item.optionList)}%`,
                  }"
                ></div>
                <div class="option-text_copy">
                  {{ `${opt.optionKey}、${opt.optionTitle || ""}` }}
                </div>
                <div class="option-text">
                  {{ `${opt.optionKey}、${opt.optionTitle || ""}` }}
                </div>
                <div class="option-icon">
                  <img :src="require('./img/check.png')" />
                </div>
                <div class="option-data" v-if="isView">
                  <div class="label">{{ opt.checkedCount || 0 }}人</div>
                  <div class="value">
                    {{ `${getPercent(opt, item.optionList)}%` }}
                  </div>
                </div>
              </div>
            </div>
            <!-- 文本 -->
            <div class="input" v-if="item.quType === 3">
              <textarea
                :disabled="isView"
                v-model="item.answer"
                :placeholder="isView ? '' : '请输入内容'"
                :maxlength="200"
                :auto-height="true"
              ></textarea>
            </div>
            <!-- 上传 -->
            <div
              class="upload"
              v-if="item.quType === 4 && setField(item, 'imgUrls')"
            >
              <v-upload
                class="oItem-b_upload"
                :disabled="isView"
                :imgUrl.sync="item.imgUrls"
                :activityPicture.sync="item.imgUrls"
                ref="load"
              ></v-upload>
              <div
                class="upload-empty"
                v-if="isView && item.imgUrls.length === 0"
              >
                暂无内容
              </div>
            </div>
            <!-- 矩阵量表题 -->
            <div class="scale" v-if="item.quType === 5">
              <div
                class="option-wrap"
                v-for="(opt, oIndex) in item.optionList"
                :key="oIndex"
              >
                <div class="option-text">
                  {{ `${opt.optionKey}、${opt.optionTitle || ""}` }}
                </div>
                <div class="option-block">
                  <div
                    class="block-color"
                    v-for="(v, i) in scaleColorOps"
                    :key="i"
                    :style="{
                      backgroundColor: opt.point >= i + 1 ? v : '',
                    }"
                    :class="{ 'no-border': opt.point >= i + 1 }"
                    @click="setOptAnswer(opt, item, i)"
                  >
                    <span v-if="!isView">{{ i + 1 }}分</span>
                    <div class="option-data" v-if="isView">
                      <div class="label">
                        {{
                          opt.checkedCountExt
                            ? opt.checkedCountExt.split(",")[i]
                            : 0
                        }}人
                      </div>
                      <div class="value">
                        {{
                          `${getPercent(
                            opt.checkedCountExt
                              ? opt.checkedCountExt.split(",")[i]
                              : 0,
                            item.optionList,
                            5
                          )}%`
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer" v-if="!isView && listData.length > 0" @click="submit">
      {{ subLoading ? "提交中..." : "提交" }}
    </div>
  </div>
</template>

<script>
import { getTopicInfo, getmyMyTopicInfo, getSubmitQuestionnaire } from "./api";
import { Dialog } from "vant";
import { mapState } from "vuex";
// import uploadPicList from "../../components/uploadPicList.vue";
export default {
  components: {},
  data() {
    return {
      name: "questionnaireDetail",
      scrollInfoView: "",
      isHas: false, // 用于判断调哪个详情接口, 是否参与 true是 false否
      subLoading: false, // 判断接口是否请求中
      title: "", // 问卷标题
      answerCount: 0, // 参与人数
      description: "", // 详情
      endDate: "", // 结束日期
      quStatus: undefined, // 状态：1：未开始 2：进行中 3：结束
      hasAnswer: 0, // 是否参与 1 是 2 否
      hasAnswerMap: {
        0: "未知",
        1: "已参与",
        2: "未参与",
      },
      scaleColorOps: [
        "#DCE6F7FF",
        "#CDDEFCFF",
        "#A4C3FAFF",
        "#367BF4B3",
        "#367BF4D1",
      ],
      listData: [],
      id: "",
      isView: false,
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  async created(query) {
    const { id } = this.$route.query;
    this.id = id;
    this.getDetail();
  },
  methods: {
    // 获取详情
    async getDetail() {
      let res = await this.$axios.get(getTopicInfo, {
        params: {
          topicId: this.id,
          userId: this.userId,
        },
      });
      if (res.code === 200 && res.data) {
        const data = res.data || {};
        this.listData = this.dealData(data.questionList);
        this.title = data.title;
        this.answerCount = data.answerCount;
        this.endDate = data.endDate;
        this.quStatus = data.quStatus;
        this.hasAnswer = data.hasAnswer;
        this.isHas = this.hasAnswer === 1;
        // this.detail = res.data;

        let { awTimes, hasAnswer, todayAwTimes } = data;
        /*
         * awTimes	回答次数：0-不限定，1-仅可以一次，2-一天一次
         * hasAnswer	是否回答：1:是;2:否
         * todayAwTimes	今天回答次数
         */
        let onOff = true;
        if (awTimes === 0) {
          // 不限定，则不会为true
          onOff = false;
          this.listData = this.dealData(data.questionList, !onOff);
        } else if (awTimes === 1) {
          // 仅可以一次且已参与
          onOff = hasAnswer === 1;
        } else if (awTimes === 2) {
          // 一天一次 且今天已回答
          onOff = todayAwTimes === 1;
          this.listData = this.dealData(data.questionList, !onOff);
        }
        // 当状态不为 进行中，或者 onOff 为 true 时，仅能查看
        this.isView = this.quStatus !== 2 || onOff;
        console.log(res.data, this.isView, "res.data");
        // this.detail.topicContent = regImg(this.detail.topicContent);
        // this.onRefresh();
        this.description = data.description;
        return;
      } else {
        if (res.msg === "您没有权限查看该话题！") {
          Dialog.alert({
            title: "提示",
            message:
              "您不是本次问卷的调查对象，请前往明珠问卷查看其他问卷信息。",
          }).then(() => {
            this.$router.push({
              name: "questionnaireList",
            });
          });

          return;
        }
      }
    },
    // 获取进度条百分比
    getPercent(opt, list, quType) {
      // opt 选项对象 item 题目对象
      if (opt) {
        // const total = list.reduce(
        //   (acc, cur) => acc + Number(cur.checkedCount) || 0,
        //   0
        // );
        const total = this.answerCount;
        const cur = quType === 5 ? opt : opt.checkedCount;
        return ((cur / total || 0) * 100).toFixed(2);
      }
      return 0;
    },
    // 获取是否选中状态
    getIsSelect(opt, item) {
      // opt 选项对象 item 题目对象
      if (item.answer) {
        const answerArr = item.answer.split(",");
        // console.log(item.answer, answerArr.includes(opt.optionKey));
        return answerArr.includes(opt.optionKey);
      }
      // 如果该字段没有，则需要手动添加
      this.$set(item, "answer", "");
      return false;
    },
    // 处理返回结果数据
    dealData(data, onOff) {
      data.forEach((v) => {
        if (onOff) {
          this.$set(v, "answer", "");
        }
        if (v.quType === 3 && v.answer === undefined) {
          this.$set(v, "answer", "");
        } else if (v.quType === 5) {
          let answer = v.answer ? v.answer.split(",") : [];
          v.optionList.forEach((opt, i) => {
            this.$set(opt, "point", answer ? answer[i] : 0);
          });
        }
      });
      console.log(data, "data");
      return data;
    },

    // 设置选项答案
    async setFOptAnswer(opt, item, i) {
      if (this.isView) {
        return;
      }
      // 未参与答题时，该字段不会返回，所以需手动添加
      if (!item.answer) {
        this.$set(item, "answer", "");
      }
      const answerArr = item.answer ? item.answer.split(",") : [];
      const index = answerArr.indexOf(opt.optionKey);
      /**
       * 如果是单选
       */
      if (item.quType === 1) {
        item.answer = opt.optionKey;
      }
      /**
       * 如果是多选
       */
      if (item.quType === 2) {
        const { maxSelection } = item;
        // 如果index存在,表示点击已选中项
        if (index !== -1) {
          answerArr.splice(index, 1);
          item.answer = answerArr.join(",");
        } else {
          if (maxSelection && answerArr.length === maxSelection) {
            // answerArr.splice(0, 1);
            // item.answer = answerArr.join(",");

            this.$toast(
              `最多选择${maxSelection}项` || "操作失败，请稍后重试！"
            );
            return;
          }
          // 反之，表示点击未选中项
          item.answer += item.answer ? `,${opt.optionKey}` : opt.optionKey;
        }
      }
      /**
       * 如果是填空
       */
      if (item.quType === 3) {
        item.answer = opt.content;
      }
      /* 如果是矩阵量表 */
      if (item.quType === 5) {
        opt.point = i + 1;
        item.answer = item.optionList.map((v) => v.point).join(",");
      }
    },
    async setOptAnswer(opt, item, i) {
      this.setFOptAnswer(opt, item, i);
    },
    // 设置字段为可监控状态
    setField(item, str, val = "") {
      if (item[str]) {
        return true;
      }
      this.$set(item, str, val);
      return true;
    },
    // 定义一个字段为 point， 用来记录用户为该选项打了几分
    // setScaleField(item, str, val = 0) {
    //   if (item[str]) {
    //     return true;
    //   }
    //   item.optionList.forEach((v, i) => {
    //     this.$set(v, str, v[str] === undefined ? val : v[str]);
    //   });
    //   console.log(item, "item---");
    //   return true;
    // },
    // 验证
    valid() {
      const listData = this.listData;
      let scrollInfoView = "";
      let title = "";
      listData.forEach((v, i) => {
        if (v.quType === 1 && v.answer === "") {
          // 单选
          scrollInfoView = `questionnaireDetail_${i}`;
          title = `第${i + 1}题未选择`;
        } else if (v.quType === 2) {
          // 多选
          let answerArr = v.answer === "" ? [] : v.answer.split(",");
          const len = answerArr.length;
          // console.log(answerArr, "answerArr---");
          let minOnOff = v.minSelection ? len < v.minSelection : false;
          let maxOnOff = v.maxSelection ? len > v.maxSelection : false;
          if (v.answer === "" || minOnOff || maxOnOff) {
            scrollInfoView = `questionnaireDetail_${i}`;
            let tip = "";
            tip = v.answer === "" ? "未选择" : "";
            tip = minOnOff ? `至少选择${v.minSelection}项` : tip;
            tip = maxOnOff ? `至多选择${v.maxSelection}项` : tip;
            title = `第${i + 1}题${tip}`;
          }
        } else if (v.quType === 3 && v.answer === "") {
          // 填空
          scrollInfoView = `questionnaireDetail_${i}`;
          title = `第${i + 1}题不可为空`;
        } else if (v.quType === 4 && v.imgUrls === "") {
          // 图片上传
          scrollInfoView = `questionnaireDetail_${i}`;
          title = `第${i + 1}题请上传图片`;
        } else if (v.quType === 5) {
          let result = false;
          v.optionList.forEach((opt) => {
            if (opt.point === 0) {
              result = true;
            }
          });
          if (result) {
            scrollInfoView = `questionnaireDetail_${i}`;
            title = `第${i + 1}题存在选项未填`;
          }
        }
      });
      this.scrollInfoView = scrollInfoView;
      let timer = setTimeout(() => {
        this.scrollInfoView = "";
        clearTimeout(timer);
      }, 0);
      if (title) {
        this.$toast(title);
      }
      return title === "";
    },
    async submit() {
      console.log("提交", this.valid());
      if (!this.valid() && !this.subLoading) {
        return;
      }
      this.subLoading = true;
      let res = await this.$axios.post(getSubmitQuestionnaire, {
        answerList: this.listData,
        topicId: this.id,
        userId: this.userId,
        houseId: this.houseId || undefined,
      });
      if (res.code == 200) {
        this.getDetail();
      } else {
        this.$toast(res.msg || "操作失败，请稍后重试！");
      }
      this.subLoading = false;
    },
  },
};
</script>

<style lang="less" scoped>
.questionnaireDetail {
  height: 100vh;
  box-sizing: border-box;
  .scroll-view {
    height: calc(100% - 96px);
    overflow-y: auto;
  }
  &.isView {
    height: 100%;
  }
  .header {
    .header-title {
      padding-top: 44px;
      font-size: 32px;
      font-weight: bold;
      color: #1a1c34;
      line-height: 44px;
      text-align: center;
      word-wrap: break-word;
      word-break: break-all;
    }
    .header-subtitle {
      display: flex;
      justify-content: center;
      margin: 32px 0 80px 0;
      .sub-wrap {
        display: flex;
        .sub-wrap_icon {
          height: 40px;
          img {
            width: 40px;
            height: 40px;
          }
        }
        .sub-wrap_text {
          height: 40px;
          display: flex;
          align-items: center;
          font-size: 24px;
          font-weight: 400;
          color: #aaaaaa;
          margin: 0 8px 0 4px;
        }
        .sub-wrap_num {
          height: 40px;
          display: flex;
          align-items: center;
          font-size: 24px;
          font-weight: bold;
          color: #666666;
        }
      }
      .sub-tag {
        box-sizing: border-box;
        height: 40px;
        display: flex;
        align-items: center;
        background: #dbedff;
        border-radius: 4px;
        border: 2px solid #acd5ff;
        font-size: 24px;
        font-weight: 400;
        color: #fe4416;
        padding: 0 12px;
        margin-left: 48px;
        &.hasAnswer {
          border: 2px solid #ffc7a4;
          background: #fff3eb;
          color: #fe4416;
        }
      }
    }
  }
  .detail {
    padding: 0 32px 32px;
  }
  .main {
    box-sizing: border-box;
    width: 100%;
    padding: 0 32px 32px;
    .content-wrap {
      .content-wrap_title {
        font-size: 32px;
        font-weight: bold;
        color: #333333;
        line-height: 44px;
        margin-bottom: 24px;
        word-wrap: break-word;
        word-break: break-all;
      }
      .content-wrap_type {
        .single,
        .multi {
          margin-bottom: 80px;
          .option-wrap {
            position: relative;
            box-sizing: border-box;
            // background: #ffffff;
            background-image: linear-gradient(90deg, #e7f0fd 0%, #accbee 100%);
            border-radius: 8px;
            // border: 2px solid #acd5ff;
            border: 2px solid #c9cacb;
            overflow: hidden;
            &:not(:last-child) {
              margin-bottom: 16px;
            }
            .option-progress {
              box-sizing: border-box;
              position: absolute;
              top: 0;
              right: 0;
              height: 100%;
              // width: 40%;
              width: 100%;
              // background: #f5f5f5;
              // border-right: 2px solid #c9cacb;
              // background: #dbedff;
              background: #fff;
              // border-right: 2px solid #acd5ff;
              border-radius: 0px 8px 8px 0px;
            }
            .option-text_copy,
            .option-text {
              font-size: 32px;
              font-weight: 400;
              color: #333333;
              line-height: 52px;
              padding: 14px 164px 14px 32px;
              word-wrap: break-word;
              word-break: break-all;
            }
            .option-text_copy {
              opacity: 0;
            }
            .option-text {
              box-sizing: border-box;
              width: 100%;
              position: absolute;
              top: 0;
              left: 0;
            }
            .option-icon {
              display: none;
              position: absolute;
              top: 50%;
              right: 110px;
              transform: translateY(-50%);
              width: 40px;
              height: 40px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .option-data {
              font-size: 24px;
              position: absolute;
              top: 50%;
              right: 12px;
              transform: translateY(-50%);
              text-align: center;
              width: 100px;
              .label {
                // border-bottom: 2px solid #333333;
              }
            }
            &.optionSelect {
              // border: 2px solid #c9cacb;
              border: 2px solid #fe4416;
              .option-progress {
                // background: #f5f5f5;
                // border-right: 2px solid #c9cacb;
                // background: #dbedff;
                // border-right: 2px solid #acd5ff;
              }
              .option-icon {
                display: block;
              }
            }
          }
        }
        .input {
          width: calc(100% - 64px);
          margin-bottom: 80px;
          border: 2px solid #c9cacb;
          padding: 20px 32px;
          color: #333333;
          border-radius: 8px;
          textarea {
            width: 100%;
            font-size: 32px;
            font-weight: 400;
            background: none;
          }
        }
        .upload {
          margin-bottom: 80px;
          .upload-empty {
            font-size: 28px;
            color: #333333;
          }
        }
        .scale {
          margin-bottom: 40px;
          color: #333333;
          .option-wrap {
            .option-text {
              font-size: 32px;
              font-weight: 400;
              color: #333333;
              line-height: 48px;
              // padding: 7px 40px 7px 16px;
              padding: 14px 0;
              word-wrap: break-word;
              word-break: break-all;
            }
            .option-block {
              display: flex;
              align-items: center;
              margin-bottom: 40px;
              .block-color {
                box-sizing: border-box;
                // flex: 1;
                width: calc(25% - 8px);
                height: 72px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #43474fff;
                background: #ffffff;
                border-radius: 4px;
                border: 2px solid #cbcbcb;
                .option-data {
                  font-size: 24px;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  width: 100%;
                  .label {
                    word-wrap: break-word;
                    word-break: break-all;
                    // border-bottom: 2px solid #333333;
                  }
                }
                &:not(:last-child) {
                  margin-right: 10px;
                }
                &:last-child {
                  color: #000000d9;
                }
              }
              .no-border {
                border: 0px solid #cbcbcb;
              }
            }
          }
        }
      }
    }
  }

  .footer {
    width: 100%;
    height: 96px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fe4416;
    color: #ffffff;
    letter-spacing: 4px;
    font-weight: bold;
  }
}
</style>
