// 问卷列表
const getTopicListURL = `/gateway/blade-system/miniapi/qu/topicList`;
// 用户信息接口
const userInfoUrl = `/gateway/hc-mini/user/mini/user-info`;
//运营位列表
const getBannerListUrl = `/gateway/hc-neighbour/topic/getTopicList`;
// 调查问卷详情
const getTopicInfo = `gateway/blade-system/miniapi/qu/topicInfo`;
// 我参与的问卷详情
const getmyMyTopicInfo = `gateway/blade-system/miniapi/qu/myTopicInfo`;
// 提交问卷
const getSubmitQuestionnaire = `gateway/blade-system/miniapi/qu/submitQuestionnaire`;

export {
  getTopicInfo,
  getmyMyTopicInfo,
  getSubmitQuestionnaire,
  getTopicListURL,
  getBannerListUrl,
  userInfoUrl,
};
